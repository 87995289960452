<template>
  <LayoutCliente>
    <TempoExpirado />
  </LayoutCliente>
</template>

<script>
import LayoutCliente from "@/Layouts/LayoutCliente.vue";
import TempoExpirado from "@/components/Cliente/TempoExpirado.vue";
export default {
  components: { LayoutCliente, TempoExpirado },
};
</script>

<style>
</style>